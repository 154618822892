@font-face {
  font-family: "Larsseit";
  src: url("./assets/fonts/Larsseit/Larsseit-Bold.otf") format("opentype");
  font-style: normal;
  font-weight: 700;
}
@font-face {
  font-family: "Larsseit";
  src: url("./assets/fonts/Larsseit/Larsseit-BoldItalic.otf") format("opentype");
  font-style: italic;
  font-weight: 700;
}
@font-face {
  font-family: "Larsseit";
  src: url("./assets/fonts/Larsseit/Larsseit-ExtraBold.otf") format("opentype");
  font-style: normal;
  font-weight: 800;
}
@font-face {
  font-family: "Larsseit";
  src: url("./assets/fonts/Larsseit/Larsseit-ExtraBoldItalic.otf")
    format("opentype");
  font-style: italic;
  font-weight: 800;
}
@font-face {
  font-family: "Larsseit";
  src: url("./assets/fonts/Larsseit/Larsseit-Italic.otf") format("opentype");
  font-style: italic;
  font-weight: 400;
}
@font-face {
  font-family: "Larsseit";
  src: url("./assets/fonts/Larsseit/Larsseit-Light.otf") format("opentype");
  font-style: normal;
  font-weight: 300;
}
@font-face {
  font-family: "Larsseit";
  src: url("./assets/fonts/Larsseit/Larsseit-LightItalic.otf")
    format("opentype");
  font-style: italic;
  font-weight: 300;
}
@font-face {
  font-family: "Larsseit";
  src: url("./assets/fonts/Larsseit/Larsseit-Medium.otf") format("opentype");
  font-style: normal;
  font-weight: 500;
}
@font-face {
  font-family: "Larsseit";
  src: url("./assets/fonts/Larsseit/Larsseit-MediumItalic.otf")
    format("opentype");
  font-style: italic;
  font-weight: 500;
}
@font-face {
  font-family: "Larsseit";
  src: url("./assets/fonts/Larsseit/Larsseit-Thin.otf") format("opentype");
  font-style: normal;
  font-weight: 100;
}
@font-face {
  font-family: "Larsseit";
  src: url("./assets/fonts/Larsseit/Larsseit-ThinItalic.otf") format("opentype");
  font-style: italic;
  font-weight: 100;
}
@font-face {
  font-family: "Larsseit";
  src: url("./assets/fonts/Larsseit/Larsseit.otf") format("opentype");
  font-style: normal;
  font-weight: 400;
}

body {
  margin: 0;
  font-family: Larsseit;
  @apply bg-siteBg;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

p {
  margin: 0;
}

[type="checkbox"] {
  color: #ec5627;
  outline: none;
}

button.bg-primary {
  font-family: Larsseit;
}

input[type="text"],
input[type="password"],
input#react-select-address-input,
textarea[type="text"],
input[type="email"],
input[type="date"],
input[type="time"] {
  @apply rounded pb-1 pt-5 px-4 2xl:pt-8;
  border-color: #dfdfdf;
  border-width: 1px !important;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.input-number {
  @apply rounded pb-1 pt-5 px-4 2xl:pt-8;
  border-color: #dfdfdf;
  border-width: 1px !important;
}

input[type="text"]::placeholder,
input[type="password"]::placeholder,
input::placeholder,
#react-select-3-input::placeholder,
input[type="email"]::placeholder,
textarea[type="text"]::placeholder,
input[type="date"]::placeholder,
input[type="time"]::placeholder {
  color: #aaaaaa !important;
}

input#react-select-address-input {
  width: 100% !important;
  box-shadow: none;
}

input:focus-visible,
textarea:focus-visible,
.react-select-address-input:focus-visible,
textarea:focus-visible,
select:focus-visible {
  outline: none !important;
  /* border-width: 0px !important; */
}

.datepicker-input:focus-visible {
  outline: none !important;
  border-width: 1px !important;
}

textarea,
input,
input#react-select-address-input {
  outline: none;
}

input:focus-within ~ label,
input:not(:placeholder-shown) ~ label {
  /* @apply transform scale-75 -translate-y-6; */
}

input[type="text"]:focus-within ~ label,
input[type="password"]:focus-within ~ label,
input[type="email"]:focus-within ~ label,
textarea:focus-within ~ label,
input#react-select-address-input:focus-within ~ label {
  @apply text-secondary opacity-100;
}

.custom-scroll::-webkit-scrollbar {
  width: 15px;
  height: 5px;
}
.custom-scroll::-webkit-scrollbar-track {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
}
.custom-scroll::-webkit-scrollbar-thumb {
  background-color: #d1d1d1;
  border-radius: 10px;
}

.mobile-scroll::-webkit-scrollbar {
  width: 0px;
  height: 5px;
}
.mobile-scroll::-webkit-scrollbar-track {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
}
.mobile-scroll::-webkit-scrollbar-thumb {
  background-color: #d1d1d1;
  border-radius: 10px;
}

input[type="range"] {
  @apply rounded-full w-full h-1.5;
  -webkit-appearance: none;
  outline: none;
}

input[type="range"]::-webkit-slider-thumb {
  @apply rounded-full;
  -webkit-appearance: none !important;
  appearance: none !important;
  width: 20px; /* Set a specific slider handle width */
  height: 20px; /* Slider handle height */
  background: #ffffff;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.12);
  cursor: pointer; /* Cursor on hover */
}

input[type="date"]::-webkit-datetime-edit {
  line-height: normal !important;
  height: auto !important;
}

input[type="time"]::-webkit-datetime-edit {
  line-height: normal !important;
  height: auto !important;
}

@import "./assets/css/index.css";
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
