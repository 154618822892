.defaultButton {
  @apply relative bg-secondary p-3;
}

.defaultButtonText {
  @apply text-white text-base 2md:text-lg;
}

.action-btn {
  width: 30px !important;
  height: 30px !important;
  background: #ffffff;
  border: 1px solid #ececf5;
  @apply rounded-full mr-2;
}

.action-btn-secondary {
  width: 30px !important;
  height: 30px !important;
  background: #ffffff;
  border: 1px solid #d8d8d8;
  @apply rounded-full mr-2;
}

.action-btn-custom {
  width: 40px !important;
  height: 40px !important;
  background: #ffffff;
  border: 1px solid #d8d8d8;
  @apply rounded-full mr-2;
}
