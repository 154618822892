input:focus-visible,
textarea:focus-visible,
select:focus-visible {
  outline: none !important;
}
.google-autocomplete-input [type="text"]:focus {
  --tw-ring-color: transparent !important;
}

.site-sidabar {
  width: 350px;
}

.logo-bar {
  margin-left: 40px;
  margin-bottom: 30px;
}

.logo-bar svg {
  width: 189px;
}

.main-wrapper {
  width: 100%;
  max-width: 100%;
}

.sidebar-link {
  margin-left: 40px;
  margin-bottom: 25px;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 140%;
  letter-spacing: 0.015em;
}

.sidebar-link:hover svg path,
.sidebar-link.is-active svg path {
  fill: white;
}

.sidebar-icon-bg {
  width: 35px;
  height: 35px;
}

.text-decoration {
  text-decoration-color: #ec5627 !important;
  text-decoration-style: solid !important;
  text-decoration-thickness: 2px !important;
  text-underline-offset: 20px !important;
}

.flex-middle {
  @apply flex;
  @apply justify-center;
  @apply items-center;
}

.site-header {
  padding: 0 29px;
}
.site-header .profile-button {
  width: 35px;
  height: 35px;
  border-radius: 100%;
  background-color: #ffc145;
}

.profile-name .profile-name-text {
  /* font-family: Larsseit; */
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 150%;

  letter-spacing: 0.015em;
}

.alert {
  @apply bg-secondary;
  @apply justify-between;
  @apply flex;
  @apply items-center;
  @apply gap-4;
  @apply text-white;
  padding: 17px 29px;
}
.main-wrapper {
  padding: 17px 29px;
}
.alert svg path {
  fill: white;
}
.alert-button {
  width: 27px;
  height: 27px;
  border-radius: 100%;
  @apply bg-secondaryDark;
}

.close {
  @apply w-5 h-5 opacity-40;
  cursor: pointer;
  content: url("./assets/svg/close.svg");
}

.close-video {
  cursor: pointer;
  content: url("./assets/svg/close-video.svg");
  width: 12px;
  height: 12px;
}

.drop-shadow-2 {
  filter: drop-shadow(0px 16px 32px rgba(35, 23, 5, 0.26)) !important;
}

.bg-size-full {
  background-size: 100% 100%;
}

.icon-container-stats {
  width: 48px !important;
  height: 48px !important;
  background: #f0f0f0 !important;
  @apply rounded-full flex justify-center items-center;
}

.notification-panel {
  min-width: 500px;
  height: 100vh;
  padding: 32px 50px;
}

.custom-plan-panel {
  min-width: 500px;
  height: 100vh;
}

.transaction-panel {
  min-width: 686px;
  height: 100vh;
  background: #f8f8fb;
  border: 1px solid #ececf5;
  box-shadow: 0px 4px 20px rgba(53, 63, 111, 0.12);
}

.account-panel {
  min-width: 500px;
  height: 100vh;
  background: #ffffff;
  border: 1px solid #d8d8d8;
  box-shadow: 0px 4px 20px rgba(53, 63, 111, 0.12);
}

.affiliate-panel {
  min-width: 500px;
  height: 100vh;
  background: #ffffff;
  border: 1px solid #d8d8d8;
  box-shadow: 0px 4px 20px rgba(53, 63, 111, 0.12);
}

.account-avatar {
  width: 70px !important;
  height: 70px !important;
  @apply rounded-full;
}

.transaction-header {
  height: 98px !important;
  @apply px-8 pl-9 pr-3;
}

.transaction-content {
  background: "#fffff" !important;
  box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.07) !important;
  @apply p-10;
}

.list-style {
  list-style: none !important;
}

.list-style li:before {
  content: "✓";
  padding-right: 10px;
  color: #4ed988;
}

.bg-footer-transaction {
  @apply px-10 py-3;
  background: rgba(232, 232, 232, 0.5) !important;
}

input:checked ~ .dot {
  transform: translateX(100%);
  background-color: white;
}

.notification-dropbox {
  @apply bg-notification-dropbox border-dotted border-notification-border-gray border-2 w-full rounded-md flex items-center justify-center;
  height: 140px;
}

/* Date Range Picker */
.calendarWrap {
  position: relative;
}

.calendarElement {
  position: absolute;
  left: 100%;
  transform: translateX(-100%);
  top: 60px;
  border: 1px solid #ccc;
  z-index: 9999;
}

.rdrDateDisplay,
.rdrInputRange {
  display: none !important;
}

/* pagination css */
.pagination {
  justify-content: center;
  display: flex;
  padding-left: 0;
  list-style: none;
}

.page-item .page-link {
  position: relative;
  display: block;
  margin: 0 2px;
  border: 1px solid #dfdfdf;
  padding: 5px 13px;
  border-radius: 5px;
  color: #28265b;
  text-decoration: none;
}

.page-item a.page-link:hover {
  color: #ffffff;
  background-color: #28265b;
}

.page-item.active .page-link {
  color: #ffffff;
  background-color: #28265b;
}

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}
/* pagination end css */
