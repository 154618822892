.page-wrapper {
  @apply h-full;
}

.main-page-wrapper {
  @apply w-full;
  @apply 2md:px-10;
}

.form-page-wrapper {
  @apply relative h-screen bg-secondary flex flex-col items-center justify-center;
}

.form-container {
  @apply bg-white flex flex-col items-center justify-center rounded-xl py-10
        w-8/12
        md:w-6/12
        2md:w-4/12
        2xl:w-3/12;
}

.form-logo-container {
  @apply flex justify-center pb-5
        /* absolute top-8
        2md:top-16
        lg:top-32
        2xl:top-52
        3xl:top-80 */;
}

.form-logo {
  @apply w-1/4
        md:w-20
        lg:w-5/12
        2xl:w-7/12;
}

/* Column Section */
.form-column-title {
  @apply text-center pb-6 text-xl w-10/12
        md:pb-3
        2md:pb-6 2md:text-2xl
        lg:pb-10 lg:text-3xl
        2xl:pb-12 2xl:text-4xl
        3xl:pb-16 3xl:text-5xl;
}

.form-column-subtitle {
  @apply text-center text-xs pb-6 w-10/12
        md:pb-3
        2md:pb-6 2md:text-sm
        lg:pb-10 lg:text-sm
        2xl:pb-12 2xl:text-base
        3xl:pb-16 3xl:text-lg;
}

.form-column-title-req {
  @apply text-center pb-0  text-xl
        2md:text-2xl
        lg:text-3xl
        2xl:text-4xl
        3xl:text-5xl;
}

.form-column-container {
  @apply w-10/12;
}

.form-input-container {
  @apply flex-1 overflow-hidden p-1 mb-3;
}

.form-input-container input[type="date"],
.form-input-container input[type="time"] {
  @apply rounded-md border-gray;
}

.form-input-container input::-webkit-inner-spin-button,
.form-input-container input::-webkit-calendar-picker-indicator {
  -webkit-appearance: none;
  opacity: 0;
}

.form-input-container input[type="date"].schedule-field {
  padding-right: 0.65rem !important;
}

.form-input-container
  input[type="time"].schedule-field::-webkit-datetime-edit-ampm-field {
  display: none;
}

.form-input-container input[type="time"].schedule-field {
  padding-right: 0.2rem !important;
}

.form-input-container input[type="date"]::before {
  content: attr(placeholder);
  position: absolute;
  color: #999999;
}

.form-input-container input[type="date"] {
  color: #ffffff;
}

.form-input-container input[type="date"]:focus,
.form-input-container input[type="date"]:valid {
  color: #000;
}

.form-input-container input[type="date"]:focus::before,
.form-input-container input[type="date"]:valid::before {
  content: "";
}

.form-input-container input[type="time"]::before {
  content: attr(placeholder);
  position: absolute;
  color: #999999;
}
.form-input-container input[type="time"]::-webkit-calendar-picker-indicator {
  padding: 0rem 1.5rem;
}

.form-input-container input[type="time"] {
  color: #ffffff;
}

.form-input-container input[type="time"]:focus,
.form-input-container input[type="time"]:valid {
  color: #000;
}

.form-input-container input[type="time"]:focus::before,
.form-input-container input[type="time"]:valid::before {
  content: "";
}

.form-input-container-notification {
  @apply flex-1 overflow-hidden p-1 mr-5;
}
.checkbox-container {
  @apply mb-8 mt-3 pl-1 flex flex-row justify-between items-center;
}

.forgot-password-link {
  @apply text-xs text-secondary cursor-pointer
        2md:text-sm
        2xl:text-lg
        3xl:text-lg;
}

.no-account-text {
  @apply text-center font-light text-xs
        2md:text-sm
        2xl:text-lg
        3xl:text-lg;
}

.no-account-link {
  @apply text-secondary cursor-pointer;
}

.checkbox-text {
  @apply text-xs font-light
        2md:text-sm
        2xl:text-lg
        3xl:text-lg;
}
