/* wilson */
.mt-23px {
  margin-top: 23px !important;
}

.mt-22px {
  margin-top: 22px !important;
}

.mt-15px {
  margin-top: 15px !important;
}

.mb-29px {
  margin-bottom: 29px !important;
}

.py-25px {
  padding-top: 25px !important;
  padding-bottom: 25px !important;
}

.py-29px {
  padding-top: 29px !important;
  padding-bottom: 29px !important;
}

.px-30px {
  padding-left: 30px !important;
  padding-right: 30px !important;
}

.px-31px {
  padding-left: 31px !important;
  padding-right: 31px !important;
}

.px-46px {
  padding-left: 46px !important;
  padding-right: 46px !important;
}

.text-32px {
  font-size: 32px !important;
  line-height: 150% !important;
}

.stats-span {
  font-weight: 700;
  font-size: 15.0606px;
  line-height: 150%;
  letter-spacing: 0.015em;
  color: #6fcf97;

  background: rgba(111, 207, 151, 0.12);
  border-radius: 5.37879px;
  @apply px-1.5 py-0.5;
}

/* table */
.dthead {
  @apply border-b border-thBorder px-4 pb-3.5 text-sm opacity-50;
}

.dtbody {
  padding-top: 26px !important;
  padding-bottom: 28px !important;
  @apply px-4 border-b border-thBorder;
}

.account-img {
  width: 18px !important;
  height: 18px !important;
}

.user-img {
  width: 40px !important;
  height: 40px !important;
}

.user-img-sm {
  width: 30px !important;
  height: 30px !important;
}

.padding-34px {
  padding: 34px !important;
}

/* datepicker */
.font-larsseit {
  font-family: Larsseit;
}

/* 
Custom CSS for react-datepicker by HackerOne
Original Author: 	msnegurski https://github.com/msnegurski
Edited and Added Features: Wilson Joseph Hinacay https://github.com/wilsonJosephHinacay
gray-300 - rgb(209 213 219);
gray-200 - rgb(229 231 235);
gray-800 - rgb(31 41 55);
gray-400 - rgb(156 163 175);
gray-700 - rgb(55 65 81);
blue-500 - rgb(59 130 246);
blue-200 - rgb(191 219 254);
*/
.react-datepicker__input-container input {
  @apply rounded-md px-4;
  @apply block w-full appearance-none outline-none bg-white text-base;
  line-height: 1;
  border-color: #dfdfdf;
  border-width: 1px !important;
  padding-left: 1.2rem !important;
  padding-right: 2.4rem !important;
  padding-top: 0.6rem !important;
  padding-bottom: 0.6rem !important;
}

.react-datepicker-popper {
  @apply z-50 w-72 text-sm bg-white shadow px-3 py-2 border-2 border-gray-200 rounded mt-2;
}

.react-datepicker-left {
  @apply absolute left-0 right-auto top-11 transform-none !important;
}

.react-datepicker-right {
  @apply absolute right-0 left-auto top-11 transform-none !important;
}

.react-datepicker__portal {
  @apply absolute z-10 w-72 text-sm transform-none bg-white shadow px-3 py-2 top-12 right-0 border-2 border-gray-200 rounded;
}

.react-datepicker__month-container {
  @apply flex flex-col;
}

.react-datepicker__month {
  @apply flex flex-col;
}

.react-datepicker__current-month {
  @apply ml-2.5 text-lg font-semibold text-gray-800;
}

.react-datepicker__week {
  @apply flex justify-around;
}

.react-datepicker__day-names {
  @apply flex justify-around text-gray-400 font-medium text-center text-xs;
}

.react-datepicker__day-name {
  @apply w-8 h-8 flex items-center justify-center py-1 rounded-full;
}

.react-datepicker__navigation {
  @apply absolute top-2;
}

.react-datepicker__navigation--previous {
  @apply right-12 w-8 h-8 rounded transition flex items-center justify-center hover:bg-gray-200;
}

.react-datepicker__navigation--next {
  @apply right-4 w-8 h-8 rounded transition flex items-center justify-center hover:bg-gray-200;
}

.react-datepicker__day {
  @apply mb-1 w-8 h-8 flex items-center justify-center py-1 text-sm leading-loose transition text-gray-700 rounded border-2 border-transparent hover:border-blue-500;
}

.react-datepicker__day--disabled {
  @apply cursor-not-allowed opacity-40 hover:bg-transparent;
}

.react-datepicker__day--outside-month {
  @apply text-gray-300;
}

.react-datepicker__day--in-range {
  @apply bg-gray-200;
}

.react-datepicker__day--in-selecting-range {
  @apply bg-blue-200;
}

.react-datepicker__day--selecting-range-start {
  @apply bg-white border-2 border-blue-500;
}

.react-datepicker__day--selecting-range-end {
  @apply bg-white border-2 border-blue-500;
}

.react-datepicker__day--selected {
  @apply bg-blue-500 text-white;
}

.react-datepicker__day--range-start {
  @apply bg-blue-500 text-white hover:text-gray-700 hover:bg-white;
}

.react-datepicker__day--range-end {
  @apply bg-blue-500 text-white hover:text-gray-700 hover:bg-white;
}

.react-datepicker--time-only {
  max-height: 300px !important;
  overflow: auto !important;
}

.react-datepicker__header--time--only {
  @apply text-base font-bold tracking-tighter;
}

.react-datepicker__time-list-item {
  @apply py-2 px-2.5 text-md tracking-tighter cursor-pointer hover:bg-primary hover:text-white;
}

.react-datepicker--time-only::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
.react-datepicker--time-only::-webkit-scrollbar-track {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
}
.react-datepicker--time-only::-webkit-scrollbar-thumb {
  background-color: #d1d1d1;
  border-radius: 10px;
}
/* end CSS for react-datepicker */
